import React, { useEffect, useState } from "react";
import lanPort from "../../../assets/images/lan-port.png";
import noSignal from "../../../assets/images/no-signal.svg";
import dotSignal from "../../../assets/images/dot-signal.svg";
import oneSignal from "../../../assets/images/one-signal.svg";
import twoSignal from "../../../assets/images/two-signal.svg";
import fullSignal from "../../../assets/images/full-signal.svg";
import allApi from "../../../api/allApi";
import Loader from "../common/Loader";

function Client() {
  const [wiredClients, setWiredClients] = useState([]);
  const [wirelessClients, setWirelessClients] = useState([]);
  const [disconnectedClients, setDisconnectedClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("all");
  const [refreshing, setRefreshing] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState({
    deviceName: "",
    connDeviceName: "",
    connDeviceModel: "",
    ssid: "",
    port: "",
    platform: "",
    macAddress: "",
    ipAddress: "",
  });
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [connectedPage, setConnectedPage] = useState(1); // Pagination state for connected clients
  const [disconnectedPage, setDisconnectedPage] = useState(1);
  const devicesPerPage = 10;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      setRefreshing(true);
      const api = new allApi();
      const connectedPromise = api.getRequest("api/client/connected");
      const disconnectedPromise = api.getRequest("api/client/disconnected");
      const [connectedResponse, disconnectedResponse] = await Promise.all([
        connectedPromise,
        disconnectedPromise,
      ]);
      const { wiredClients, wirelessClients } = connectedResponse;
      setWiredClients(wiredClients);
      setWirelessClients(wirelessClients);
      setDisconnectedClients(disconnectedResponse);
    } catch (error) {
      console.error("Error fetching client data:", error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const getFilteredClients = () => {
    let clients = [];
    switch (filter) {
      case "wired":
        clients = wiredClients;
        break;
      case "wireless":
        clients = wirelessClients;
        break;
      case "all":
      default:
        clients = [...wiredClients, ...wirelessClients];
        break;
    }

    const filteredClients = clients.filter((client) =>
      Object.keys(searchCriteria).every((key) =>
        searchCriteria[key]
          ? (client[key]?.toString() || "")
            .toLowerCase()
            .includes(searchCriteria[key].toLowerCase())
          : true
      )
    );

    if (sortConfig.key) {
      filteredClients.sort((a, b) => {
        const aVal = a[sortConfig.key]?.toString().toLowerCase() || "";
        const bVal = b[sortConfig.key]?.toString().toLowerCase() || "";
        if (aVal < bVal) return sortConfig.direction === "asc" ? -1 : 1;
        if (aVal > bVal) return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      });
    }

    const start = (connectedPage - 1) * devicesPerPage;
    const end = start + devicesPerPage;
    return filteredClients.slice(start, end);
  };

  const getFilteredDisconnectedClients = () => {
    return disconnectedClients.filter((client) =>
      Object.keys(searchCriteria).every((key) =>
        searchCriteria[key]
          ? (client[key]?.toString()?.toLowerCase() || "").includes(
            searchCriteria[key].toLowerCase()
          )
          : true
      )
    );
  };


  const totalFilteredDevices = getFilteredClients().length;
  const totalFilteredDisconnectedDevices = getFilteredDisconnectedClients().length;

  const getPaginatedDisconnectedClients = () => {
    const start = (disconnectedPage - 1) * devicesPerPage;
    const end = start + devicesPerPage;
    return getFilteredDisconnectedClients().slice(start, end);
  };

  const totalPages = (totalFilteredDevices) =>
    Math.ceil(totalFilteredDevices / devicesPerPage);
  const totalPagesDisconnected = (totalFilteredDisconnectedDevices) =>
    Math.ceil(totalFilteredDisconnectedDevices / devicesPerPage);
  const indexOfFirstDevice = (currentPage) =>
    (currentPage - 1) * devicesPerPage;

  const handlePrevPage = (type) => {
    if (type === "connected") {
      setConnectedPage((prev) => Math.max(prev - 1, 1));
    } else {
      setDisconnectedPage((prev) => Math.max(prev - 1, 1));
    }
  };

  const handleNextPage = (type) => {
    if (type === "connected") {
      setConnectedPage((prev) =>
        Math.min(prev + 1, totalPages(totalFilteredDevices))
      );
    } else {
      setDisconnectedPage((prev) =>
        Math.min(
          prev + 1,
          totalPagesDisconnected(totalFilteredDisconnectedDevices)
        )
      );
    }
  };

  const handlePageClick = (page, type) => {
    if (type === "connected") {
      setConnectedPage(page);
    } else {
      setDisconnectedPage(page);
    }
  };

  const handleLastPage = (type) => {
    if (type === "connected") {
      setConnectedPage(totalPages(totalFilteredDevices));
    } else {
      setDisconnectedPage(
        totalPagesDisconnected(totalFilteredDisconnectedDevices)
      );
    }
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchCriteria((prev) => ({ ...prev, [name]: value }));
  };

  const handleSort = (header) => {
    const key = columnMapping[header];
    if (!key) return;
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  const columnMapping = {
    "Client Name": "deviceName",
    Connection: "clientType",
    "Associated Device": "connDeviceName",
    "Device Model": "connDeviceModel",
    SSID: "ssid",
    Port: "port",
    OS: "platform",
    "MAC Address": "macAddress",
    "IP Address": "ipAddress",
    RSSI: "rssi",
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <ul className="nav nav-underline" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link um active"
                  id="connected-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#connected-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="connected-tab-pane"
                  aria-selected="true"
                >
                  Connected
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link rm"
                  id="disconnected-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#disconnected-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="disconnected-tab-pane"
                  aria-selected="false"
                >
                  Disconnected
                </button>
              </li>
            </ul>
            <div className="inc-card-button user-button">
              {/* <button className="icon-btn" title="Download">
                <i className="fa fa-download" aria-hidden="true"></i>
              </button>
              <button className="icon-btn" title="Search">
                <i className="fa fa-search" aria-hidden="true"></i>
              </button> */}
              <button className="icon-btn" title="Refresh" onClick={fetchData}>
                {refreshing ? (
                  <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-refresh" aria-hidden="true"></i>
                )}
              </button>
            </div>
          </div>
          <div className="inc-card-body">
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="connected-tab-pane"
                role="tabpanel"
                aria-labelledby="connected-tab"
                tabIndex="0"
              >
                <div className="tab-pagination">
                  <div className="client-con-type">
                    <span>Connection Type:</span>
                    <label>
                      <input
                        type="radio"
                        name="connectionType"
                        checked={filter === "all"}
                        onChange={() => setFilter("all")}
                      />
                      ALL ({wiredClients.length + wirelessClients.length}){" "}
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="connectionType"
                        checked={filter === "wired"}
                        onChange={() => setFilter("wired")}
                      />
                      Wired ({wiredClients.length}){" "}
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="connectionType"
                        checked={filter === "wireless"}
                        onChange={() => setFilter("wireless")}
                      />
                      Wireless ({wirelessClients.length}){" "}
                    </label>
                  </div>
                  <div className="inc-card-table client-connection">
                    <table className="m-head">
                      <thead>
                        <tr>
                          {[
                            "Client Name",
                            "Connection",
                            "Associated Device",
                            "Device Model",
                            "SSID",
                            "Port",
                            "OS",
                            "MAC Address",
                            "IP Address",
                            "RSSI",
                          ].map((header, index) => (
                            <th key={index} onClick={() => handleSort(header)}>
                              {header}{" "}
                              {sortConfig.key === header &&
                                (sortConfig.direction === "asc" ? "▲" : "▼")}
                            </th>
                          ))}
                        </tr>
                        <tr className="search-border">
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="deviceName"
                              value={searchCriteria.deviceName}
                              onChange={handleSearchChange}
                              placeholder="Search Device Name"
                            />
                          </td>
                          <td></td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="connDeviceName"
                              value={searchCriteria.connDeviceName}
                              onChange={handleSearchChange}
                              placeholder="Search connected device name"
                            />
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="connDeviceModel"
                              value={searchCriteria.connDeviceModel}
                              onChange={handleSearchChange}
                              placeholder="Search Device Model"
                            />
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="ssid"
                              value={searchCriteria.ssid}
                              onChange={handleSearchChange}
                              placeholder="Search SSID"
                            />
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="port"
                              value={searchCriteria.port}
                              onChange={handleSearchChange}
                              placeholder="Search Port"
                            />
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="platform"
                              value={searchCriteria.platform}
                              onChange={handleSearchChange}
                              placeholder="Search OS"
                            />
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="macAddress"
                              value={searchCriteria.macAddress}
                              onChange={handleSearchChange}
                              placeholder="Search MAC Address"
                            />
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="ipAddress"
                              value={searchCriteria.ipAddress}
                              onChange={handleSearchChange}
                              placeholder="Search IP address"
                            />
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td colSpan="6">
                              <Loader />
                            </td>
                          </tr>
                        ) : getFilteredClients().length > 0 ? (
                          getFilteredClients().map((client) => (
                            <tr key={client.deviceName}>
                              <td>{client.deviceName}</td>
                              <td>
                                {client.clientType === "wired" ? (
                                  <img src={lanPort} />
                                ) : (
                                  <img
                                    style={{ height: "25px" }}
                                    src={fullSignal}
                                  />
                                )}
                              </td>
                              <td>{client.connDeviceName}</td>
                              <td>{client.connDeviceModel}</td>
                              <td>{client.ssid || "N/A"}</td>
                              <td>{client.port || "N/A"}</td>
                              <td>{client.platform || "N/A"}</td>
                              <td>{client.macAddress}</td>
                              <td>{client.ipAddress}</td>
                              <td className="rssi-icon">
                                {client.rssi >= 80 ? (
                                  <img src={fullSignal} />
                                ) : client.rssi >= 60 ? (
                                  <img src={twoSignal} />
                                ) : client.rssi >= 40 ? (
                                  <img src={oneSignal} />
                                ) : client.rssi >= 20 ? (
                                  <img src={dotSignal} />
                                ) : (
                                  <img src={noSignal} />
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="10">No Results Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>

                  </div>
                  <div className="pagination-container">
                    <div className="pagination-info">
                      {totalFilteredDevices > 0 ? (
                        <p>
                          Showing {indexOfFirstDevice(connectedPage) + 1} -{" "}
                          {Math.min(
                            connectedPage * devicesPerPage,
                            totalFilteredDevices
                          )}
                          &nbsp; of {totalFilteredDevices} records
                        </p>
                      ) : (
                        <p>No Records Available</p>
                      )}
                    </div>

                    <div className="pagination">
                      <button
                        className="prev-btn"
                        onClick={() => handlePrevPage("connected")}
                        disabled={connectedPage === 1}
                      >
                        Previous
                      </button>
                      {[...Array(totalPages(totalFilteredDevices))].map(
                        (_, index) => (
                          <button
                            key={index}
                            className={`page-btn ${connectedPage === index + 1 ? "active" : ""
                              }`}
                            onClick={() =>
                              handlePageClick(index + 1, "connected")
                            }
                          >
                            {index + 1}
                          </button>
                        )
                      )}
                      <button
                        className="next-btn"
                        onClick={() => handleNextPage("connected")}
                        disabled={connectedPage === totalPages(totalFilteredDevices)}
                      >
                        Next
                      </button>
                      <button
                        className="last-btn"
                        onClick={() => handleLastPage("connected")}
                        disabled={connectedPage === totalPages(totalFilteredDevices)}
                      >
                        Last Page
                      </button>
                    </div>
                  </div>
                </div>

              </div>
              <div
                className="tab-pane fade"
                id="disconnected-tab-pane"
                role="tabpanel"
                aria-labelledby="disconnected-tab"
                tabIndex="0"
              >
                <div className="tab-pagination">
                  <div className="inc-card-table">
                    <table className="m-head">
                      <thead>
                        <tr>
                          <th>Client Name</th>
                          <th>Connection</th>
                          <th>Associated Device</th>
                          <th>Device Model</th>
                          <th>SSID</th>
                          <th>Port</th>
                          <th>OS</th>
                          <th>MAC Address</th>
                          <th>IP Address</th>
                          <th>RSSI</th>
                        </tr>
                        <tr className="search-border">
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="deviceName"
                              value={searchCriteria.deviceName}
                              onChange={handleSearchChange}
                              placeholder="Search Device Name"
                            />
                          </td>
                          <td></td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="connDeviceName"
                              value={searchCriteria.connDeviceName}
                              onChange={handleSearchChange}
                              placeholder="Search connected device name"
                            />
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="connDeviceModel"
                              value={searchCriteria.connDeviceModel}
                              onChange={handleSearchChange}
                              placeholder="Search Device Model"
                            />
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="ssid"
                              value={searchCriteria.ssid}
                              onChange={handleSearchChange}
                              placeholder="Search SSID"
                            />
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="port"
                              value={searchCriteria.port}
                              onChange={handleSearchChange}
                              placeholder="Search Port"
                            />
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="platform"
                              value={searchCriteria.platform}
                              onChange={handleSearchChange}
                              placeholder="Search OS"
                            />
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="macAddress"
                              value={searchCriteria.macAddress}
                              onChange={handleSearchChange}
                              placeholder="Search MAC Address"
                            />
                          </td>
                          <td>
                            <input
                              className="searchBox smallWidth"
                              type="text"
                              name="ipAddress"
                              value={searchCriteria.ipAddress}
                              onChange={handleSearchChange}
                              placeholder="Search IP address"
                            />
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td colSpan="10">
                              <Loader />
                            </td>
                          </tr>
                        ) : getFilteredDisconnectedClients().length > 0 ? (
                          getFilteredDisconnectedClients().map((client) => (
                            <tr key={client.clientId}>
                              <td>{client.deviceName}</td>
                              <td>
                                {client.clientType === "wired" ? (
                                  <img src={lanPort} />
                                ) : (
                                  <img
                                    style={{ height: "25px" }}
                                    src={fullSignal}
                                  />
                                )}
                              </td>
                              <td>{client.connDeviceName || "N/A"}</td>
                              <td>{client.connDeviceModel || "N/A"}</td>
                              <td>{client.ssid || "N/A"}</td>
                              <td>{client.port || "N/A"}</td>
                              <td>{client.platform || "N/A"}</td>
                              <td>{client.macAddress || "N/A"}</td>
                              <td>{client.ipAddress || "N/A"}</td>
                              <td className="rssi-icon">
                                {client.rssi >= 80 ? (
                                  <img src={fullSignal} />
                                ) : client.rssi >= 60 ? (
                                  <img src={twoSignal} />
                                ) : client.rssi >= 40 ? (
                                  <img src={oneSignal} />
                                ) : client.rssi >= 20 ? (
                                  <img src={dotSignal} />
                                ) : (
                                  <img src={noSignal} />
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="10">No Results Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>

                  </div>
                  <div className="pagination-container">
                    <div className="pagination-info">
                      {totalFilteredDisconnectedDevices > 0 ? (
                        <p>
                          Showing {indexOfFirstDevice(disconnectedPage) + 1} -{" "}
                          {Math.min(
                            disconnectedPage * devicesPerPage,
                            totalFilteredDisconnectedDevices
                          )}
                          &nbsp; of {totalFilteredDisconnectedDevices} records
                        </p>
                      ) : (
                        <p>No Records Available</p>
                      )}
                    </div>

                    <div className="pagination">
                      <button
                        className="prev-btn"
                        onClick={() => handlePrevPage("connected")}
                        disabled={disconnectedPage === 1}
                      >
                        Previous
                      </button>
                      {[...Array(totalPages(totalFilteredDisconnectedDevices))].map(
                        (_, index) => (
                          <button
                            key={index}
                            className={`page-btn ${disconnectedPage === index + 1 ? "active" : ""
                              }`}
                            onClick={() =>
                              handlePageClick(index + 1, "disconnected")
                            }
                          >
                            {index + 1}
                          </button>
                        )
                      )}
                      <button
                        className="next-btn"
                        onClick={() => handleNextPage("disconnected")}
                        disabled={disconnectedPage === totalPages(totalFilteredDisconnectedDevices)}
                      >
                        Next
                      </button>
                      <button
                        className="last-btn"
                        onClick={() => handleLastPage("disconnected")}
                        disabled={disconnectedPage === totalPages(totalFilteredDisconnectedDevices)}
                      >
                        Last Page
                      </button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Client;
