import React, { useState, useEffect, useCallback } from "react";
import allApi from "../../../api/allApi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import Loader from "../common/Loader";
import auditc1 from "../../../assets/images/audit-1.png";
import auditc2 from "../../../assets/images/audit-2.png";
import debounce from "lodash.debounce";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

function Audit() {
  const [selectedRows, setSelectedRows] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [fullDetails, setFullDetails] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [auditLogs, setAuditLogs] = useState([]);
  const [logsPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [refreshing, setRefreshing] = useState(false);
  const [originalAuditLogs, setOriginalAuditLogs] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState("24hr");
  const [selectedEdgeInterval, setSelectedEdgeInterval] = useState("24hr");
  const [selectedUserInterval, setSelectedUserInterval] = useState("24hr");
  const [totalItems,setTotalItems] = useState(1);

  const handleIntervalChange = (interval) => {
    setSelectedInterval(interval);
    getDeviceAudit(interval);
  };
  const handleEdgeIntervalChange = (interval) => {
    setSelectedEdgeInterval(interval);
    getEdgeAudit(interval);
  };
  const handleUserIntervalChange = (interval) => {
    setSelectedUserInterval(interval);
    getUserAudit(interval);
  };

  const [userAuditData, setUserAuditData] = useState({
    active: 0,
    inactive: 0,
    created: 0,
    deleted: 0,
    updated: 0,
  });
  const [userChartData, setUserChartData] = useState([]);
  const [deviceAuditData, setDeviceAuditData] = useState({
    added: 0,
    deleted: 0,
    updated: 0,
    configPushed: 0,
    firmwareAudits: 0,
  });
  const [deviceChartData, setDeviceChartData] = useState([]);
  const [edgeAuditData, setEdgeAuditData] = useState({
    created: 0,
    updated: 0,
    deleted: 0,
  });
  const [chartData, setChartData] = useState([]);
  const [searchTerms, setSearchTerms] = useState({
    createdTime: "",
    deviceModel: "",
    userId: "",
    deviceId: "",
    actionDetail: "",
  });
  const [sortOrder, setSortOrder] = useState({
    email: "asc",
    actionStatus: "asc",
    createdTime: "asc",
    protocol_type: "asc",
    description: "asc",
    details: "asc",
  });
  const COLORS = ["#ff6b6b", "#26bf78", "#118AB2", "#ffad01", "#800080"];
  useEffect(() => {
    fetchAuditLogs(currentPage);
  }, [currentPage]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, []);
  useEffect(() => {
    getUserAudit(selectedUserInterval);
  }, []);
  useEffect(() => {
    getDeviceAudit(selectedInterval);
  }, []);
  useEffect(() => {
    getEdgeAudit(selectedEdgeInterval);
  }, []);

  // function truncateData(data) {
  //   if (!data || data === "null") return "null";

  //   try {
  //     const parsedData = JSON.parse(data);
  //     if (parsedData && parsedData.id) {
  //       return `${parsedData.id}, ${parsedData.name}, ${parsedData.serial_number}`;
  //     } else {
  //       return "Invalid Data";
  //     }
  //   } catch (error) {
  //     return "Invalid Data";
  //   }
  // }

  async function fetchAuditLogs(page) {
    setLoading(true);
    setRefreshing(true);
    try {
      const api = new allApi();
      let response = await api.getRequest(`api/audit/all?page=${page}&size=${logsPerPage}`);
      if (!response.items) {
        throw new Error("Failed to fetch audit logs");
      }
      setAuditLogs(response.items);
      //  setLogsPerPage(response.length);
      setOriginalAuditLogs(response.items);
      setTotalPages(response.totalPages);
      setTotalItems(response.totalItems);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching audit logs:", error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  }

  const getUserAudit = async (interval = "24hr") => {
    try {
      const api = new allApi();
      const response = await api.getRequest(`api/audit/user-audit/${interval}`);
      if (response && Array.isArray(response)) {
        const auditData = {
          active:
            response.find((item) => item.Action === "Logged_In_User")?.Count ||
            0,
          inactive:
            response.find((item) => item.Action === "User_Inactive")?.Count ||
            0,
          created:
            response.find((item) => item.Action === "User_Created")?.Count || 0,
          deleted:
            response.find((item) => item.Action === "User_Deleted")?.Count || 0,
          updated:
            response.find((item) => item.Action === "User_Updated")?.Count || 0,
        };
        setUserAuditData(auditData);
        setUserChartData([
          { name: "LoggedIn", value: auditData.active },
          { name: "Inactive", value: auditData.inactive },
          { name: "Created", value: auditData.created },
          { name: "Deleted", value: auditData.deleted },
          { name: "Updated", value: auditData.updated },
        ]);
      }
    } catch (error) {
      console.error("Error fetching user audit data", error);
    }
  };
  const getDeviceAudit = async (interval = "24hr") => {
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/audit/device-audit/${interval}`
      );
      if (response && Array.isArray(response)) {
        const auditData = {
          added:
            response.find((item) => item.Action === "DEVICE_ADDED")?.Count || 0,
          deleted:
            response.find((item) => item.Action === "DEVICE_DELETED")?.Count ||
            0,
          updated:
            response.find((item) => item.Action === "DEVICE_UPDATED")?.Count ||
            0,
          configPushed:
            response.find((item) => item.Action === "CONFIG_PUSHED")?.Count ||
            0,
          firmwareAudits:
            response.find((item) => item.Action === "FIRMWARE_AUDITS")?.Count ||
            0,
        };
        setDeviceAuditData(auditData);
        setDeviceChartData([
          { name: "Added", value: auditData.added },
          { name: "Deleted", value: auditData.deleted },
          { name: "Updated", value: auditData.updated },
          { name: "Config", value: auditData.configPushed },
          { name: "FW Audits", value: auditData.firmwareAudits },
        ]);
      }
    } catch (error) {
      console.error("Error fetching device audit data", error);
    }
  };

  const totalActions =
    deviceAuditData.added +
    deviceAuditData.deleted +
    deviceAuditData.updated +
    deviceAuditData.configPushed +
    deviceAuditData.firmwareAudits;

  const percentage =
    totalActions > 0
      ? ((deviceAuditData.configPushed / totalActions) * 100).toFixed(2)
      : 0;

  const getEdgeAudit = async (interval = "24hr") => {
    try {
      const api = new allApi();
      const response = await api.getRequest(`api/audit/edge-audit/${interval}`);
      if (response && Array.isArray(response)) {
        const auditData = {
          created:
            response.find((item) => item.Action === "EDGE_ADDED")?.Count || 0,
          updated:
            response.find((item) => item.Action === "EDGE_UPDATED")?.Count || 0,
          deleted:
            response.find((item) => item.Action === "EDGE_DELETED")?.Count || 0,
        };
        setEdgeAuditData(auditData);
        setChartData([
          { name: "Created", value: auditData.created },
          { name: "Updated", value: auditData.updated },
          { name: "Deleted", value: auditData.deleted },
        ]);
      }
    } catch (error) {
      console.error("Error fetching audit data", error);
    }
  };

  const handleSelectAllCheckbox = (event) => {
    if (event.target.checked) {
      const allIndexes = Array.from(Array(auditLogs.length).keys());
      setSelectedRows(allIndexes);
    } else {
      setSelectedRows([]);
    }
  };

  const selectAllChecked = selectedRows.length === auditLogs.length;

  const toggleRowSelection = (index) => {
    if (selectedRows.includes(index)) {
      setSelectedRows(selectedRows.filter((item) => item !== index));
    } else {
      setSelectedRows([...selectedRows, index]);
    }
  };

  const togglePopup = (details) => {
    setShowPopup((prevShowPopup) => !prevShowPopup);
    setFullDetails(details);
  };

  // const handleMouseEnter = () => {
  //   setShowPopup(true);
  // };

  // const handleMouseLeave = () => {
  //   setShowPopup(false);
  // };

  const toggleSwitch = () => {
    setIsEnabled(!isEnabled);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleLastPage = () => {
    setCurrentPage(totalPages);
    fetchAuditLogs(totalPages);
  };
  const handleRefresh = () => {
    fetchAuditLogs(currentPage);
  };

  const handleSearchFilterByName = async (page, name, value) => {
    setLoading(true);
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/audit/all?page=${page}&size=50&column=${name}&value=${value}`
      );
      if (!response.items) {
        throw new Error("Failed to fetch filtered audit logs");
      }
      setAuditLogs(response.items);
    } catch (error) {
      console.error("Error fetching filtered audit logs:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearch = useCallback(
    debounce((name, value) => handleSearchFilterByName(name, value), 800),
    []
  );

  const handleSearch = (e) => {
    const { name, value } = e.target;
    setSearchTerms({ ...searchTerms, [name]: value.toLowerCase() });
    if (value) {
      debouncedSearch(name, value);
    } else {
      setAuditLogs(originalAuditLogs);
    }
  };

  const handleSearchBox = (header) => {
    console.log("Clicked on header:", header);
  };

  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const totalFilteredLogs = auditLogs.length;
  const currentLogs = auditLogs.slice(indexOfFirstLog, indexOfLastLog);

  const getPageNumbers = () => {
    const range = 1;
    const start = Math.max(1, currentPage - range);
    const end = Math.min(totalPages, currentPage + range);

    return [...Array(end - start + 1).keys()].map((i) => start + i);
  };

  const handleSortChange = (field) => {
    // Toggle the sort order for the clicked field
    const newOrder = sortOrder[field] === "asc" ? "desc" : "asc";

    // Sort the gateways based on the field and order
    const sortedLogs = [...auditLogs].sort((a, b) => {
      // Handle undefined values gracefully
      const aValue = a[field] ? a[field].toString().toLowerCase() : "";
      const bValue = b[field] ? b[field].toString().toLowerCase() : "";

      if (newOrder === "asc") {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    });

    // Update the state with the new order and sorted gateways
    setAuditLogs(sortedLogs);
    setSortOrder((prevState) => ({
      ...prevState,
      [field]: newOrder, // Set the new sort order for the field
    }));
  };

  return (
    <div>
      <div className="row mb-4">
        <div className="col-lg-4">
          <div className="inc-card mb-4">
            <div className="inc-card-title">
              <h3>Edge</h3>
              <span
                className="dropdown"
                id="edgeDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  {selectedEdgeInterval}
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </span>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="edgeDropdown"
                >
                  {["8hr", "24hr", "7days", "30days", "3months", "6months"].map(
                    (interval) => (
                      <li key={interval}>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => handleEdgeIntervalChange(interval)}
                        >
                          {interval}
                        </a>
                      </li>
                    )
                  )}
                </ul>
              </span>
            </div>
            <div className="inc-card-body">
              <div>
                <div className="deviceCountChart audit-edge-chart">
                  <div className="device-chart">
                    <div>
                      {chartData && chartData.length > 0 ? (
                        <div className="c-chart">
                          <ResponsiveContainer aspect={2.3}>
                            <BarChart
                              width={400}
                              height={200}
                              data={chartData}
                              margin={{
                                top: 20,
                                right: 30,
                                left: -20,
                                bottom: 5,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="name" />
                              <YAxis />
                              <Tooltip />
                              <Bar dataKey="value" fill="#82ca9d">
                                {chartData.map((entry, index) => (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                  />
                                ))}
                              </Bar>
                            </BarChart>
                          </ResponsiveContainer>
                        </div>
                      ) : (
                        <div>No data to display</div>
                      )}
                    </div>
                  </div>
                  <div className="c-legend">
                      <ul>
                        {chartData.map((entry, index) => (
                          <li key={index}>
                            <span
                              className="legend-color"
                              style={{
                                backgroundColor: COLORS[index % COLORS.length],
                              }}
                            ></span>
                            {entry.name} : <strong>{entry.value}</strong>
                          </li>
                        ))}
                      </ul>

                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="col-lg-3">
          <div className="inc-card mb-4">
            <div className="inc-card-body">
              <div className="card-top">
                <div>
                  <h3>Tenant</h3>
                </div>
                <div>
                  <span className="a-yellow">
                    <i className="fa fa-pie-chart" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
              <div className="audit-card-count aud-yellow">
                <div>
                  <div>
                    <span></span>
                    <strong>13</strong>Active
                  </div>
                  <div>
                    <span></span>
                    <strong>28</strong>Created
                  </div>
                </div>
                <div>
                  <div>
                    <span></span>
                    <strong>30</strong>Deleted
                  </div>
                  <div>
                    <span></span>
                    <strong>67</strong>Updated
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="inc-card">
            <div className="inc-card-body">
              <div className="card-top">
                <div>
                  <h3>MQTT</h3>
                </div>
                <div>
                  <span className="a-blue">
                    <i className="fa fa-pie-chart" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
              <div className="audit-card-count aud-blue">
                <div>
                  <div>
                    <span></span>
                    <strong>45</strong>No of connections
                  </div>
                </div>
                <div>
                  <div>
                    <span></span>
                    <strong>44</strong>Data bytes transfered
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="col-lg-4">
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>User Audit</h3>
              <span
                className="dropdown"
                id="edgeDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  {selectedUserInterval}
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </span>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="edgeDropdown"
                >
                  {["8hr", "24hr", "7days", "30days", "3months", "6months"].map(
                    (interval) => (
                      <li key={interval}>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => handleUserIntervalChange(interval)}
                        >
                          {interval}
                        </a>
                      </li>
                    )
                  )}
                </ul>
              </span>
            </div>
            <div className="inc-card-body">
              <div>
                <div className="deviceCountChart">
                  <div className="device-chart">
                    <div>
                      {userChartData && userChartData.length > 0 ? (
                        <div className="c-chart">
                          <ResponsiveContainer aspect={2.3}>
                            <BarChart
                              width={400}
                              height={200}
                              data={userChartData}
                              margin={{
                                top: 20,
                                right: 30,
                                left: 0,
                                bottom: 5,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis
                                dataKey="name"
                                interval={0}
                              />
                              <YAxis />
                              <Tooltip />
                              <Bar dataKey="value" fill="#8884d8">
                                {userChartData.map((entry, index) => (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                  />
                                ))}
                              </Bar>
                            </BarChart>
                          </ResponsiveContainer>
                        </div>
                      ) : (
                        <div>No data to display</div>
                      )}
                    </div>
                  </div>
                  <div className="c-legend">
                    <ul>
                      {userChartData.map((entry, index) => (
                        <li key={index}>
                          <span
                            className="legend-color"
                            style={{
                              backgroundColor: COLORS[index % COLORS.length],
                            }}
                          ></span>
                          {entry.name} : <strong>{entry.value}</strong>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>Device Audit</h3>
              <span
                className="dropdown"
                id="edgeDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  {selectedInterval}
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </span>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="edgeDropdown"
                >
                  {["8hr", "24hr", "7days", "30days", "3months", "6months"].map(
                    (interval) => (
                      <li key={interval}>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => handleIntervalChange(interval)}
                        >
                          {interval}
                        </a>
                      </li>
                    )
                  )}
                </ul>
              </span>
            </div>
            <div className="inc-card-body">
              <div>
                <div className="deviceCountChart">
                  <div className="device-chart">
                    <div>
                      {deviceChartData && deviceChartData.length > 0 ? (
                        <div className="c-chart">
                          <ResponsiveContainer aspect={2.3}>
                            <BarChart
                              width={400}
                              height={200}
                              data={deviceChartData}
                              margin={{
                                top: 20,
                                right: 30,
                                left: -20,
                                bottom: 5,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis
                                dataKey="name"
                                interval={0}
                                //angle={-20}
                                //textAnchor="end"
                              />
                              <YAxis />
                              <Tooltip />
                              <Bar dataKey="value" fill="#8884d8">
                                {deviceChartData.map((entry, index) => (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                  />
                                ))}
                              </Bar>
                            </BarChart>
                          </ResponsiveContainer>
                        </div>
                      ) : (
                        <div>No data to display</div>
                      )}
                    </div>
                  </div>
                  <div className="c-legend">
                    <ul>
                      {deviceChartData.map((entry, index) => (
                        <li key={index}>
                          <span
                            className="legend-color"
                            style={{
                              backgroundColor: COLORS[index % COLORS.length],
                            }}
                          ></span>
                          {entry.name} : <strong>{entry.value}</strong>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="inc-card audit-table">
        <div className="inc-card-title">
          <h3>Audit Logs</h3>
          <div className="inc-card-button">
            <span className="filter-pop">
              <button
                className="icon-btn"
                title="filter"
                onClick={() => setIsOpen(!isOpen)}
              >
                {" "}
                <i className="fa fa-filter" aria-hidden="true"></i>
              </button>
              {isOpen && (
                <div className="device-filter" aria-labelledby="deviceFilter">
                  <div className="filter-title">
                    <span>Filter</span>
                    <a href="#">Clear</a>
                  </div>
                  <div className="filter-body">
                    <ul>
                      <li>
                        <label>
                          <input type="radio" name="radio" /> Last
                        </label>
                        <div className="row">
                          <div className="col-lg-10">
                            <select>
                              <option>1 Day</option>
                              <option>2 Day</option>
                              <option>3 Day</option>
                              <option>4 Day</option>
                            </select>
                          </div>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={isEnabled}
                              onChange={toggleSwitch}
                            />
                            <span className="slider round"></span>
                          </label>
                          {isEnabled && (
                            <div className="col-lg-10 last-filter">
                              <input
                                type="text"
                                className="cal-input"
                                placeholder="Day"
                              />
                              <input
                                type="text"
                                className="cal-input"
                                placeholder="Hr"
                              />
                              <input
                                type="text"
                                className="cal-input"
                                placeholder="Min"
                              />
                              <input
                                type="text"
                                className="cal-input"
                                placeholder="sec"
                              />
                            </div>
                          )}
                        </div>
                      </li>
                      <li>
                        <label>
                          <input type="radio" name="radio" /> Time period
                        </label>
                        <div className="row">
                          <div className="col-lg-6">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              className="cal-input"
                            />
                          </div>
                          <div className="col-lg-6">
                            <DatePicker
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              className="cal-input"
                            />{" "}
                          </div>
                        </div>
                      </li>
                      <li>
                        <label>
                          <input type="radio" name="radio" /> Interval
                        </label>
                        <select>
                          <option>1 Day</option>
                          <option>2 Day</option>
                          <option>3 Day</option>
                          <option>4 Day</option>
                        </select>
                      </li>
                    </ul>
                  </div>
                  <div className="filter-button">
                    <button
                      className="lineBtn"
                      onClick={() => setIsOpen(false)}
                    >
                      Cancel
                    </button>
                    <button className="fillBtn">Apply</button>
                  </div>
                </div>
              )}
            </span>
            <button
              className="icon-btn"
              title="Refresh"
              onClick={handleRefresh}
            >
              {refreshing ? (
                <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
              ) : (
                <i className="fa fa-refresh" aria-hidden="true"></i>
              )}
            </button>
          </div>
        </div>
        <div className="inc-card-body">
          <div className="inc-card-table half">
            <table className="full-table m-head">
              <thead>
                <tr>
                  <th>
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        onChange={handleSelectAllCheckbox}
                      />
                      <span className="indicator"></span>
                    </label>
                  </th>
                  {/* <th>Audit ID</th> */}
                  <th>User Email</th>
                  <th>Action Status</th>
                  <th>Log Time</th>
                  <th>Device Type</th>
                  <th>Description</th>
                </tr>
                <tr className="search-border">
                  <td></td>
                  {/* <td>
                    <input
                      className="searchBox smallWidth"
                      type="text"
                      name="auditId"
                      onKeyUp={(e) => handleSearch(e)}
                    />
                    <i
                      className="fa fa-sort-amount-desc"
                      onClick={() => handleSearchBox("auditId")}
                      aria-hidden="true"
                    ></i>
                  </td> */}
                  <td>
                    <input
                      className="searchBox smallWidth"
                      type="text"
                      name="email"
                      onKeyUp={(e) => handleSearch(e)}
                    />{" "}
                    <i
                      className={`fa ${
                        sortOrder.email === "asc"
                          ? "fa fa-sort-amount-desc"
                          : "fa fa-sort-amount-asc"
                      }`}
                      onClick={() => handleSortChange("email")}
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td>
                    <input
                      className="searchBox smallWidth"
                      type="text"
                      name="actionStatus"
                      onKeyUp={(e) => handleSearch(e)}
                    />{" "}
                    <i
                      className={`fa ${
                        sortOrder.actionStatus === "asc"
                          ? "fa fa-sort-amount-desc"
                          : "fa fa-sort-amount-asc"
                      }`}
                      onClick={() => handleSortChange("actionStatus")}
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td>
                    <input
                      className="searchBox smallWidth"
                      type="text"
                      name="createdTime"
                      onKeyUp={(e) => handleSearch(e)}
                    />
                    <i
                      className={`fa ${
                        sortOrder.createdTime === "asc"
                          ? "fa fa-sort-amount-desc"
                          : "fa fa-sort-amount-asc"
                      }`}
                      onClick={() => handleSortChange("createdTime")}
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td>
                    <input
                      className="searchBox smallWidth"
                      type="text"
                      name="deviceType"
                      onKeyUp={(e) => handleSearch(e)}
                    />
                    <i
                      className={`fa ${
                        sortOrder.protocol_type === "asc"
                          ? "fa fa-sort-amount-desc"
                          : "fa fa-sort-amount-asc"
                      }`}
                      onClick={() => handleSortChange("protocol_type")}
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td>
                    <input
                      className="searchBox smallWidth"
                      type="text"
                      name="description"
                      onKeyUp={(e) => handleSearch(e)}
                    />
                    <i
                      className={`fa ${
                        sortOrder.description === "asc"
                          ? "fa fa-sort-amount-desc"
                          : "fa fa-sort-amount-asc"
                      }`}
                      onClick={() => handleSortChange("description")}
                      aria-hidden="true"
                    ></i>
                  </td>
                </tr>
              </thead>

              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="6">
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  auditLogs.map((log, index) => (
                    <tr key={index}>
                      <td>
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            checked={selectedRows.includes(index)}
                            onChange={() => toggleRowSelection(index)}
                          />
                          <span className="indicator"></span>
                        </label>
                      </td>
                      <td>{log.email || "N/A"}</td>
                      <td>{log.actionStatus || "N/A"}</td>
                      <td>{log.createdTime || "N/A"}</td>
                      <td>
                        {log.details?.info
                          ? Array.from(
                              new Set(
                                log.details.info.map(
                                  (info) => info.protocol_type
                                )
                              )
                            ).join(", ")
                          : "N/A"}
                      </td>
                      <td>{log.description || "N/A"}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="pagination-container">
          <div className="pagination-info">
            <p>
              Showing {indexOfFirstLog + 1}-
              {Math.min(
                currentPage * logsPerPage,totalItems
                // totalFilteredLogs
              )}{" "}
              of {totalItems} records
            </p>
          </div>
          {totalPages > 1 && (
            <div className="pagination">
              <button
                onClick={() => handlePageClick(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              {getPageNumbers().map((page) => (
                <button
                  key={page}
                  onClick={() => handlePageClick(page)}
                  className={currentPage === page ? "active" : ""}
                >
                  {page}
                </button>
              ))}
              <button
                onClick={() => handlePageClick(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
              <button onClick={handleLastPage} disabled={currentPage === totalPages}>
                Last Page
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Audit;
